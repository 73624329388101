let config = {
    s3: {
        REGION: "us-east-2",
        BUCKET: "forwood-vale-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-vale-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-east-2",
        URL: "https://vi546i2dcjafzniso65r6q3nxe.appsync-api.us-east-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-east-2",
        URL: "https://3c8ca9rid1.execute-api.us-east-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://9ktnk0nfyd.execute-api.us-east-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.vale.forwoodsafety.com",
        WEBSOCKET: "wss://ytsci10m9h.execute-api.us-east-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-2",
        USER_POOL_ID: "us-east-2_WBDx22fhW",
        IDENTITY_POOL_ID: "us-east-2:76aff446-45a1-435c-85c5-656733726c27",
        USERPOOL_HOSTED_DOMAIN: "forwood-vale-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.vale.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.vale.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::796837152198:role/prod-CA"
    },
    samlProvider: {
      NAME: 'Vale',
      SUPPORTED_LIST: 'Vale,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.36.2",
        HOSTNAME: "id.vale.forwoodsafety.com",
        ENV_NAME: "prod",
        COOKIE_DOMAIN: ".vale.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "45563fb2-5bf6-48e4-beae-ebb6547700b9",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.vale.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
